<template>
    <div class="movementBox">
        <div class="mbTitle">
            <p class="mtBig">威有动静</p>
            <p class="mtSmall">
                对新事物、新环境、新技术、新观念,都有敏锐的感受力和接受力<span @click="$router.push('/movement')">
                    查看全部文章 ></span
                >
            </p>
        </div>
        <div class="mbBox" v-if="moList2.length && moList3.length">
            <!-- 左边部分 -->
            <div class="moLeft">
                <div class="mlContent" @click="goPage(moList2[0].id)">
                    <!-- 公司简介 -->
                    <div class="mcTopText">
                        <!-- 公司logo -->
                        <div class="mcLogo">
                            <img class="mlImg" :src="moList2[0].head_img" alt="" />
                        </div>
                        <!-- 公司介绍 -->
                        <div class="mcText">
                            <p class="mtBig">{{ moList2[0].title }}</p>
                            <p class="mtSmall">
                                {{ moList2[0].intro }}
                            </p>
                        </div>
                    </div>
                    <!-- 日期 -->
                    <div class="mcBottomData">
                        <div class="mcDay">{{ moList2[0].dateDay }}</div>
                        <div class="mcYear">{{ moList2[0].date }}</div>
                        <div class="mcMore">
                            <!-- <i class="iconfont icon-a-youjiantouzhixiangyoujiantou"></i> -->
                            <img class="mmIcon" src="http://iv.vu818.com/img/vu818.png" alt="" />
                        </div>
                    </div>
                </div>
                <div class="mlContent" @click="goPage(moList2[1].id)">
                    <!-- 公司简介 -->
                    <div class="mcTopText">
                        <!-- 公司logo -->
                        <div class="mcLogo">
                            <img class="mlImg" :src="moList2[1].head_img" alt="" />
                        </div>
                        <!-- 公司介绍 -->
                        <div class="mcText">
                            <p class="mtBig">{{ moList2[1].title }}</p>
                            <p class="mtSmall">
                                {{ moList2[1].intro }}
                            </p>
                        </div>
                    </div>
                    <!-- 日期 -->
                    <div class="mcBottomData">
                        <div class="mcDay">{{ moList2[1].dateDay }}</div>
                        <div class="mcYear">{{ moList2[1].date }}</div>
                        <div class="mcMore">
                            <!-- <i class="iconfont icon-a-youjiantouzhixiangyoujiantou"></i> -->
                            <img class="mmIcon" src="http://iv.vu818.com/img/vu818.png" alt="" />
                        </div>
                    </div>
                </div>
            </div>
            <!-- 中间大区域 -->
            <div class="moCenter" @click="goPage(moList2[2].id)">
                <!-- 日期和介绍 -->
                <div class="mcYearText">
                    <div class="mytLeft">
                        <p class="mytNum">{{ moList2[2].dateDay }}</p>
                    </div>
                    <div class="mytRight">
                        <p class="mytYear">{{ moList2[2].date }}</p>
                        <p class="mytText">{{ moList2[2].title }}</p>
                    </div>
                </div>
                <div class="mcBigLogo">
                    <!-- <img :src="moList2[2].head_img" class="mblImg" /> -->
                    <img src="https://vuapi.vu818.com/file/article/e9746978cb0c8ad95318b9413baab444.png" class="mblImg" />
                </div>
            </div>
            <!-- 右边部分 -->
            <div class="moRight">
                <ul>
                    <li v-for="(item, index) in moList3" :key="index" @click="goPage(item.id)">
                        <div class="mrLeft">0{{ index + 1 }}</div>
                        <div class="mrRight">
                            <p class="mrYear">{{ item.date }}</p>
                            <p class="mrText">{{ item.title }}</p>
                        </div>
                        <div class="mrBtn">
                            <!-- <i class="iconfont icon-a-youjiantouzhixiangyoujiantou"></i> -->
                            <img class="mmIcon" src="http://iv.vu818.com/img/vu818.png" alt="" />
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
export default {
    name: "Movement",
    data() {
        return {
            moList2: [],
            moList3: [],
        };
    },
    async created() {
        let { data } = await axios.get("/api/article/hotshow");
        this.moList2 = data.left.map(e => {
            let date = e.create_time.split(" ")[0];
            return {
                head_img: e.little_image,
                title: e.title,
                intro: e.intro,
                date: date.split("-").slice(0, 2).join("-"),
                dateDay: date.split("-")[2].length == 1 ? "0" + date.split("-")[2] : date.split("-")[2],
                id: e.id,
            };
        });
        this.moList3 = data.right.map(e => {
            let date = e.create_time.split(" ")[0];
            return {
                title: e.title,
                date: date.split("-").slice(0, 2).join("-"),
                id: e.id,
            };
        });
    },
    methods: {
        goPage(id) {
            this.$router.push(`/movementDetail/${id}`);
        },
    },
};
</script>

<style lang="less" scoped>
.movementBox {
    // max-width: 1280px;
    height: 600px;
    margin: 0 auto;
    text-align: left;
    .mbTitle {
        width: 70%;
        height: 90px;
        margin: 0 auto;
        // margin-top: 30px;
        text-align: center;
        .mtBig {
            width: 100%;
            height: 50px;
            line-height: 50px;
            font-size: 36px;
        }
        .mtSmall {
            width: 100%;
            height: 30px;
            line-height: 30px;
            font-size: 16px;
            margin-top: 10px;
            span {
                color: #7781f1;
                cursor: pointer;
            }
        }
    }
    .mbBox {
        width: 100%;
        height: 460px;
        // margin-top: 20px;
        display: flex;
        justify-content: space-around;
        margin-top: 20px;
    }
    // 左边部分
    .moLeft {
        width: 24%;
        height: 100%;
        margin-right: 1%;
        margin-left: 1%;
        .mlContent {
            width: 100%;
            height: 48%;
            border-radius: 10px;
            box-shadow: 1px 1px 10px 1px gainsboro;
            // border: 2px solid white;
            cursor: pointer;
            // 公司简介
            .mcTopText {
                width: 95%;
                height: 48%;
                margin: 0 auto;
                border-bottom: 1px solid gainsboro;
                box-sizing: border-box;
                // logo
                .mcLogo {
                    width: 30%;
                    height: 100%;
                    float: left;
                    text-align: center;
                    .mlImg {
                        width: 90%;
                        height: 80%;
                        margin-top: 13%;
                        border-radius: 10px;
                    }
                }
                // 介绍
                .mcText {
                    width: 67%;
                    height: 100%;
                    float: left;
                    margin-left: 5px;
                    .mtBig {
                        width: 98%;
                        height: 60%;
                        line-height: 30px;
                        font-size: 15px;
                        font-weight: 900;
                    }
                    .mtSmall {
                        width: 100%;
                        height: 40%;
                        line-height: 20px;
                        font-size: 12px;
                        text-indent: 12px;
                        color: black;
                        -webkit-line-clamp: 2;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
            }
            // 日期
            .mcBottomData {
                width: 94%;
                height: 47%;
                margin: 0 auto;
                margin-top: 2%;
                box-sizing: border-box;
                .mcDay {
                    width: 30%;
                    height: 90%;
                    line-height: 100px;
                    float: left;
                    text-align: center;
                    font-size: 50px;
                    margin-top: 2%;
                    border-right: 1px dashed gainsboro;
                    box-sizing: border-box;
                }
                .mcYear {
                    width: 50%;
                    height: 100%;
                    line-height: 100px;
                    float: left;
                    padding-left: 10px;
                    box-sizing: border-box;
                }
                .mcMore {
                    width: 42px;
                    height: 30px;
                    line-height: 30px;
                    text-align: center;
                    float: left;
                    margin-top: 35px;
                    box-shadow: 1px 1px 10px 1px gainsboro;
                    border-radius: 50px;
                    box-sizing: border-box;
                    position: relative;
                    overflow: hidden;
                    .mmIcon {
                        // width: 2000px;
                        position: absolute;
                        // top: -131px;
                        // right: -227px;
                        top: -98px;
                        right: -176px;
                    }
                    .icon-a-youjiantouzhixiangyoujiantou {
                        font-size: 25px;
                    }
                }
            }
        }
        .mlContent:nth-child(2) {
            margin-top: 20px;
        }
        .mlContent:hover {
            // border-color: #7781f1;
            box-shadow: 1px 1px 10px 1px gray;
            transition: 0.5s;
            .mcBottomData .mcMore {
                background: linear-gradient(to right, #7781f1, #7781f1e0);
                color: white;
            }
        }
    }
    // 中间大区域
    .moCenter {
        width: 34%;
        height: 100%;
        margin-left: 1%;
        border-radius: 10px;
        box-shadow: 1px 1px 10px 1px gainsboro;
        // border: 2px solid white;
        cursor: pointer;
        // 日期和介绍
        .mcYearText {
            width: 90%;
            height: 38%;
            margin: 0 auto;
            margin-top: 2%;
            .mytLeft {
                width: 30%;
                height: 80%;
                float: left;
                margin-top: 5%;
                border-right: 1px dashed gainsboro;
                box-sizing: border-box;
                .mytNum {
                    width: 80%;
                    height: 70%;
                    line-height: 100px;
                    margin: 0 auto;
                    margin-top: 15%;
                    text-align: center;
                    font-size: 50px;
                }
            }
            .mytRight {
                width: 70%;
                height: 80%;
                float: left;
                margin-top: 25px;
                padding-left: 10px;
                box-sizing: border-box;
                .mytYear {
                    width: 100%;
                    height: 30%;
                    line-height: 40px;
                }
                .mytText {
                    width: 99%;
                    height: 50%;
                    line-height: 45px;
                    font-weight: 900;
                    font-size: 15px;
                }
            }
        }
        // 大logo
        .mcBigLogo {
            width: 90%;
            height: 58%;
            margin: 0 auto;
            position: relative;
            .mblImg {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
    }
    .moCenter:hover {
        // border-color: #7781f1;
        box-shadow: 1px 1px 10px 1px gray;
        transition: 0.5s;
        .mytRight .mytText {
            color: #7781f1;
        }
    }
    // 右边部分
    .moRight {
        width: 39%;
        height: 100%;
        margin-left: 1%;
        ul {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: space-around;
            flex-direction: column;
            li {
                width: 95%;
                height: 24%;
                margin: 0 auto;
                box-sizing: border-box;
                box-shadow: 1px 1px 10px 1px gainsboro;
                border-radius: 10px;
                // border: 2px solid white;
                transition: 0.5s;
                cursor: pointer;
                .mrLeft {
                    width: 30%;
                    height: 85%;
                    line-height: 100px;
                    float: left;
                    text-align: center;
                    font-size: 50px;
                    margin-top: 2%;
                    border-right: 1px dashed gainsboro;
                    box-sizing: border-box;
                }
                .mrRight {
                    width: 50%;
                    height: 100%;
                    line-height: 100px;
                    float: left;
                    padding-left: 10px;
                    margin-top: 15px;
                    box-sizing: border-box;
                    .mrYear {
                        width: 100%;
                        height: 30%;
                        line-height: 40px;
                    }
                    .mrText {
                        width: 100%;
                        height: 50%;
                        line-height: 30px;
                        font-weight: 900;
                        font-size: 15px;
                    }
                }
                .mrBtn {
                    width: 42.86px;
                    height: 30px;
                    line-height: 30px;
                    text-align: center;
                    float: right;
                    margin-right: 15px;
                    margin-top: 65px;
                    box-shadow: 1px 1px 10px 1px gainsboro;
                    border-radius: 50px;
                    box-sizing: border-box;
                    position: relative;
                    overflow: hidden;
                    transition: 0.5s;
                    .mmIcon {
                        // width: 2000px;
                        position: absolute;
                        // top: -131px;
                        // right: -227px;
                        top: -98px;
                        right: -176px;
                    }
                    .icon-a-youjiantouzhixiangyoujiantou {
                        font-size: 20px;
                    }
                }
            }
            li:hover {
                // border-color: #7781f1;
                box-shadow: 1px 1px 10px 1px gray;
                .mrBtn {
                    background: linear-gradient(to right, #7781f1, #7781f1e0);
                    color: white;
                }
            }
        }
    }
}
</style>
