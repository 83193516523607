<template>
    <div class="topSwiper">
        <div class="topBox">
            <el-carousel
                :interval="4000"
                ref="el-carousel"
                height="100%"
                indicator-position="none"
                :autoplay="autoplay"
                @change="change"
                arrow="never"
            >
                <!-- never always -->
                <template v-for="el in lunboList">
                    <el-carousel-item :key="el.id" v-if="el.image">
                        <a
                            :href="el.url"
                            target="_blank"
                            rel="noopener noreferrer"
                            style="display: block; width: 100%; height: 100%"
                        >
                            <video
                                preload="auto"
                                :autoplay="false"
                                muted="muted"
                                :src="el.image"
                                poster=""
                                :loop="false"
                                class="elImg"
                                v-if="el.image.indexOf('.mp4') > 0"
                                :ref="'id' + el.id"
                                @pause="pause"
                            ></video>
                            <img v-else :src="el.image" class="elImg" />
                            <img v-for="e in el.children" :key="e.id" :src="e.image" class="elImgs" v-show="e.image" />
                        </a>
                    </el-carousel-item>
                </template>
            </el-carousel>
            <div class="btn-prev" @click="$refs['el-carousel'].prev()">
                <img src="https://iv.vu818.com/img/vu818.png" />
            </div>
            <div class="btn-next" @click="$refs['el-carousel'].next()">
                <img src="https://iv.vu818.com/img/vu818.png" />
            </div>
        </div>
        <div class="bottomBox">
            <div class="btbBox">
                <template v-for="el in dataList">
                    <div class="bb1" :key="el.id">
                        <div class="bbTop">
                            <div class="btImg3">
                                <img :src="el.image" class="btIcon" />
                            </div>
                            <a :href="el.url" class="btP">{{ el.title }}</a>
                        </div>
                        <div class="bbBottom">{{ el.content }}</div>
                    </div>
                </template>
                <!-- ----------- -->
                <div class="bb2" v-if="fuli && fuli.children.length">
                    <div class="bb2Left">
                        <img src="http://iv.vu818.com/img/vucloud(1).png" alt="" class="blImg" />
                    </div>
                    <div class="bb2Right">
                        <ul>
                            <template v-for="item in fuli.children">
                                <li class="brLi" :key="item.id">
                                    <a :href="item.url">{{ item.title }}</a>
                                    <div class="blIcon">
                                        <img src="http://iv.vu818.com/img/vu818.png" alt="" />
                                    </div>
                                </li>
                            </template>
                        </ul>
                    </div>
                    <div class="bb2Day">
                        <p class="bdP">离结束剩</p>
                        <p class="bdN">{{ fuli.end_time }}天</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { imgs, videos } from "@/components/customs/format";

export default {
    name: "TopSwiper",
    inject: ["getAdvData"],
    data() {
        return {
            dataList: [],
            fuli: null,
            // 自动轮播
            autoplay: false,
            // 轮播数据
            lunboList: [],
            // 视频播放中
            playing: false,
            // 正在轮播的video
            // element: {}
        };
    },
    async created() {
        let { data } = await axios.get("/api/special/list");
        this.dataList = data.filter(e => e.id != 1);
        this.fuli = data.find(e => e.id == 1);
    },
    methods: {
        // 轮播变化
        change(newIndex, oldIndex) {
            let { element } = this;
            if (element) {
                this.element = undefined;
                // 回退到开始
                element.currentTime = 0;
                element.pause();
            }

            let { lunboList } = this;
            let el = lunboList[newIndex];
            let type = videos.find(e => el.image.indexOf(e) > 0);
            if (type) {
                // 为视频
                this.autoplay = false;
                let element = this.$refs["id" + el.id][0];
                this.element = element;
                element.play();
            } else {
                this.autoplay = true;
            }
        },
        // 播放完毕了
        pause(e) {
            // 视频未播放完的情况
            if (!this.element) {
                return;
            }
            // 正常播放完的情况
            this.$refs["el-carousel"].next();
            this.element = undefined;
        },
    },
    computed: {
        list() {
            // 初始化操作
            let list = this.getAdvData(1).children;
            if (list) {
                this.lunboList = list;
                return list;
            }
            return [];
        },
    },
    watch: {
        list(newVal) {
            let { lunboList } = this;
            // 处理初始时索引0为视频的情况
            this.$nextTick(() => {
                let el = lunboList[0];
                let type = videos.find(e => el.image.indexOf(e) > 0);
                if (type) {
                    // 为视频
                    let element = this.$refs["id" + el.id][0];
                    this.element = element;
                    element.play();
                } else {
                    this.autoplay = true;
                }
            });
        },
    },
};
</script>

<style lang="less" scoped>
.topSwiper {
    // min-width: 1423px;
    width: 100%;
    position: relative;
    .topBox {
        height: 80vh;
        &:hover {
            .btn-prev,
            .btn-next {
                opacity: 1;
            }
            .btn-prev {
                left: 15px;
            }
            .btn-next {
                right: 15px;
            }
        }
        .btn-prev,
        .btn-next {
            height: 36px;
            width: 36px;
            cursor: pointer;
            transition: 0.3s;
            border-radius: 50%;
            background-color: rgba(31, 45, 61, 0.11);
            color: #fff;
            position: absolute;
            top: 50%;
            z-index: 10;
            // transform: translateY(-50%);
            text-align: center;
            font-size: 12px;
            overflow: hidden;
            opacity: 0;
            img {
                position: absolute;
                width: 920px;
                top: -48px;
                left: -705px;
            }
            &:hover {
                background-color: rgba(31, 45, 61, 0.23);
            }
        }
        .btn-prev {
            left: 10px;
            transform: translateY(-50%) rotate(90deg);
        }
        .btn-next {
            right: 10px;
            transform: translateY(-50%) rotate(-90deg);
        }
        .el-carousel {
            width: 100%;
            height: 100%;
            // height: 80vh;
            // position: absolute;
            .el-carousel__item {
                width: 100%;
                height: 100%;
                text-align: left;
                .elImg {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                    object-position: 50% 50%;
                }
                .elImgs {
                    width: 500px;
                    height: 300px;
                    position: absolute;
                    top: 25%;
                    left: 10%;
                    animation-name: slideRight;
                    animation-duration: 2s;
                    animation-timing-function: ease-in-out;
                }
                .elImgs:nth-child(3) {
                    width: 350px;
                    height: 300px;
                    position: absolute;
                    top: 15%;
                    left: 65%;
                }
                .elImgs:nth-child(4) {
                    width: 400px;
                    height: 250px;
                    position: absolute;
                    top: 50%;
                    left: 60%;
                }
            }
            @keyframes slideRight {
                0% {
                    opacity: 0;
                }
                100% {
                    opacity: 1;
                }
                0% {
                    transform: translateX(-150%);
                }
                50% {
                    transform: translateX(8%);
                }
                65% {
                    transform: translateX(-4%);
                }
                80% {
                    transform: translateX(4%);
                }
                95% {
                    transform: translateX(-2%);
                }
                100% {
                    transform: translateX(0);
                }
            }
        }
    }

    .bottomBox {
        width: 90%;
        height: 100px;
        margin-top: -50px;
        z-index: 9;
        position: relative;
        .btbBox {
            max-width: 1290px;
            height: 100px;
            margin: 0 auto;
            .bb1 {
                width: 16%;
                height: 92px;
                background-color: white;
                border-radius: 10px;
                box-shadow: 1px 1px 5px 1px gainsboro;
                cursor: pointer;
                margin-left: 20px;
                float: left;
                .bbTop {
                    width: 90%;
                    height: 40px;
                    margin: 0 auto;
                    margin-top: 15px;
                    overflow: hidden;
                    position: relative;
                    .btImg,
                    .btImg2,
                    .btImg3 {
                        width: 50px;
                        height: 40px;
                        float: left;
                        overflow: hidden;
                        position: relative;
                        .btIcon {
                            position: absolute;
                            left: -47px;
                        }
                    }
                    .btImg2 {
                        width: 45px;
                        .btIcon {
                            position: absolute;
                            left: -2px;
                        }
                    }
                    .btImg3 {
                        width: 45px;
                        .btIcon {
                            position: absolute;
                            left: -180px;
                        }
                    }
                    .btP {
                        max-width: 120px;
                        height: 100%;
                        line-height: 40px;
                        margin-left: 6px;
                        font-size: 13px;
                        float: left;
                        font-weight: bold;
                    }
                }
                .bbBottom {
                    width: 90%;
                    min-height: 20px;
                    margin: 0 auto;
                    font-size: 12px;
                    line-height: 20px;
                    color: gray;
                }
            }
            .bb1:hover {
                .btP {
                    color: #7781f1;
                }
            }
            .bb2 {
                width: 42%;
                height: 92px;
                background-color: #7781f1;
                border-radius: 10px;
                position: relative;
                float: right;
                margin-left: 20px;
                .bb2Left {
                    width: 20%;
                    height: 100%;
                    float: left;
                    .blImg {
                        width: 100px;
                        height: 80px;
                        margin: 0 auto;
                        margin-top: 5px;
                        display: block;
                    }
                }
                .bb2Right {
                    width: 70%;
                    height: 100%;
                    float: left;
                    color: white;
                    ul {
                        width: 100%;
                        height: 100%;
                        display: flex;
                        justify-content: space-around;
                        flex-wrap: wrap;
                        .brLi {
                            height: 30px;
                            line-height: 30px;
                            margin-top: 10px;
                            width: 33%;
                            a {
                                cursor: pointer;
                                color: white;
                                float: left;
                                font-size: 14px;
                            }
                            .blIcon {
                                width: 30px;
                                height: 30px;
                                float: left;
                                position: relative;
                                overflow: hidden;
                                img {
                                    position: absolute;
                                    top: -98px;
                                    right: -398px;
                                }
                            }
                        }
                        .brLi:hover {
                            .blIcon {
                                transform: translateX(5px);
                                transition: 0.5s;
                            }
                        }
                    }
                }
                .bb2Day {
                    width: 14%;
                    height: 90%;
                    background-color: white;
                    box-shadow: 1px 1px 10px 1px gainsboro;
                    border-radius: 10px;
                    margin-top: 5px;
                    float: left;
                    text-align: center;
                    position: absolute;
                    right: -5%;
                    .bdP {
                        width: 100%;
                        text-align: center;
                        height: 30px;
                        line-height: 30px;
                        color: gray;
                        font-size: 12px;
                    }
                    .bdN {
                        width: 100%;
                        text-align: center;
                        height: 60px;
                        line-height: 60px;
                        font-size: 23px;
                        color: #7781f1;
                    }
                }
            }
        }
    }
}
</style>
