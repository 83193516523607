<template>
    <!-- 首页部分合作伙伴 -->
    <div class="partCase">
        <div class="pcBox">
            <!-- 标题 -->
            <div class="pcTitle">
                <p class="pcBig">威有合作伙伴</p>
                <p class="pcSmall">跟威有合作，搞钱上瘾 &nbsp;&nbsp;<span @click="$router.push('/partner2')">查看全部 ></span></p>
            </div>
            <!-- 案例列表 -->
            <div class="pcList">
                <ul v-if="homeCase.length">
                    <li class="pcLi" v-for="item in homeCase" :key="item.id" @click="goDetails(item)">
                        <el-image fit="cover" :src="item.image" class="pclImg" />
                        <!-- 遮罩层 -->
                        <!-- <div class="pcLMask" v-if="item.qrcode">
                            <img :src="item.qrcode" class="plmCode" />
                        </div> -->
                    </li>
                </ul>
                <img v-else src="https://iv.vu818.com/img/zwsj.png" />
            </div>
            <div class="kong"></div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
export default {
    name: "PartCase",
    data() {
        return {
            homeCase: [],
        };
    },
    created() {
        this.getHc();
    },
    methods: {
        // 请求数据
        async getHc() {
            axios.get("/api/project/list_all").then(res => {
                this.homeCase = res.data;
            });
        },
        // goDetail({ id, ueditorData, project_url }) {
        //     if (ueditorData == "" || ueditorData == "<p><br></p>" || ueditorData == null) {
        //         console.log(project_url);
        //         console.log(!project_url);
        //         if (!project_url) return;
        //         if (project_url.indexOf("http") >= 0 || project_url.indexOf("https") >= 0) {
        //             window.open(project_url);
        //         } else {
        //             window.open("http://" + project_url);
        //         }
        //         return;
        //     }
        //     this.$router.push({
        //         name: "listDetail",
        //         params: { id },
        //     });
        // },
        goDetails({ id, type }) {
            if (type == 1) {
                this.$router.push("/partner3Details?id=" + id);
            } else if (type == 2) {
                this.$router.push("/partner2Details?id=" + id);
            }
        },
    },
};
</script>

<style lang="less" scoped>
.partCase {
    width: 100%;
    height: 955px;
    margin-top: 30px;
    position: relative;
    .pcBox {
        width: 100%;
        height: 100%;
        margin: 0 auto;
        overflow: hidden;
        // 标题
        .pcTitle {
            width: 40%;
            height: 90px;
            margin: 0 auto;
            text-align: center;
            .pcBig {
                width: 100%;
                height: 50px;
                line-height: 50px;
                font-size: 36px;
            }
            .pcSmall {
                width: 100%;
                height: 30px;
                line-height: 30px;
                font-size: 16px;
                margin-top: 10px;
                span {
                    color: #7781f1;
                    cursor: pointer;
                }
            }
        }
        // 案例列表
        .pcList {
            margin-top: 20px;
            width: 100%;
            // height: 500px;
            ul {
                max-width: 98%;
                height: 100%;
                margin: 0 auto;
                display: flex;
                flex-wrap: wrap;
                .pcLi {
                    width: 23%;
                    height: 240px;
                    border-radius: 20px;
                    position: relative;
                    box-shadow: 1px 1px 10px 1px gray;
                    overflow: hidden;
                    // margin-bottom: 20px;
                    margin: 0 10px 20px;
                    cursor: pointer;
                    .pclImg {
                        width: 100%;
                        height: 100%;
                        border-radius: 20px;
                        transition: 1s;
                    }
                    // 遮罩层
                    // .pcLMask {
                    //     width: 100%;
                    //     height: 100%;
                    //     border-radius: 20px;
                    //     text-align: center;
                    //     position: absolute;
                    //     top: 0;
                    //     background-color: rgb(255 252 252 / 92%);
                    //     opacity: 0;
                    //     transition: 1s;
                    //     display: flex;
                    //     flex-direction: column;
                    //     justify-content: center;
                    //     align-items: center;
                    //     .plmP {
                    //         font-weight: 900;
                    //         font-size: 25px;
                    //         padding-top: 20px;
                    //     }
                    //     .plmCode {
                    //         width: 120px;
                    //         height: 120px;
                    //         border-radius: 10px;
                    //         margin-top: 20px;
                    //     }
                    // }
                }
                .pcLi:hover {
                    .pclImg {
                        transform: scale(1.1);
                        opacity: 0.8;
                    }
                    .pcLMask {
                        opacity: 1;
                    }
                }
            }
            & > img {
                width: 300px;
            }
        }
    }
}
.kong {
    width: 100%;
    height: 100px;
    background: linear-gradient(transparent, #fff);
    z-index: 999;
    position: absolute;
    bottom: 0;
}
</style>
