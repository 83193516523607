<template>
    <div class="product2">
        <div class="p2Box">
            <div class="p2bTop">
                <p class="pt1">丰富的解决方案 聚焦产业核心场景</p>
                <div class="pt2">
                    <span class="p21">深入制造、能源、水务、政务、交通、金融等行业，助力企业数字化转型与智能化升级</span>
                    <span @click="$router.push('/Solution')" class="p22">查看全部解决方案 ></span>
                </div>
            </div>
            <div class="p2bList">
                <!-- <img width="100%" height="599px" v-show="this.theOver !== ''" :src="image1" style=""/> -->
                <transition enter-active-class="jin" leave-active-class="li">
                    <div class="iii" v-show="isShow">
                        <img width="100%" height="599px" class="image" :src="this.theOver" />
                    </div>
                </transition>
                <transition enter-active-class="up" leave-active-class="down">
                    <div class="iii" v-show="isShow" style="z-index: 8">
                        <img width="100%" height="599px" class="image" :src="this.theOver" />
                    </div>
                </transition>
                <ul @mouseleave.stop="liMove()">
                    <li
                        class="p2bLi"
                        @mouseenter.stop="liOver(index)"
                        v-for="(item, index) in p2bLiArr"
                        :key="item.id"
                        @click="$router.push(`/Solution?pid=${item.pid}&id=${item.id}`)"
                    >
                        <transition enter-active-class="jin1" leave-active-class="li1">
                            <span class="imgStatus" v-show="!isShow">
                                <img :src="item.url" alt="" class="p2Bg" />
                            </span>
                        </transition>
                        <!-- {{ item.url }} -->
                        <div class="plBox">
                            <p class="pb1">{{ item.title }}</p>
                            <p class="pb2">
                                {{ item.mark }}
                            </p>
                            <div class="pb3">
                                <p class="p3t">了解详情</p>
                                <div class="p3I">
                                    <img src="http://iv.vu818.com/img/pro2jt.png" alt="" class="p3Icon" />
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import "animate.css";
import axios from "axios";
export default {
    name: "Product2",
    data() {
        return {
            theOver: "",
            status: 1,
            // 防止结束动画还未结束就触发进入事件引发奇怪现象
            isShow: false,
            arr: [],
        };
    },
    async created() {
        this.arr = await this.getData();
    },
    methods: {
        liOver(val) {
            // 进入
            this.theOver = this.p2bLiArr[val].maxImage;
            if (this.isShow) return;
            this.isShow = true;
            let dom = document.getElementsByClassName("imgStatus");
            Object.values(dom).map(item => {
                item.style.display = "none";
            });
        },
        liMove() {
            // 离开
            this.isShow = false;
            let dom = document.getElementsByClassName("imgStatus");
            Object.values(dom).map(item => {
                item.style.display = "block";
            });
        },
        async getData() {
            let { data } = await axios.get("/api/solution/sshow");
            return data;
        },
    },
    computed: {
        p2bLiArr() {
            if (!this.arr.length) return [];
            // 随机取指定个数，但不影响之前的排序
            function get(arr, count) {
                let nnn = [];
                for (let i = 0; i < count; i++) {
                    nnn.push(sj());
                }
                function sj() {
                    let num = Math.floor(Math.random() * arr.length);
                    if (nnn.findIndex(e => e == num) != -1) return sj();
                    return num;
                }
                nnn.sort((a, b) => a - b);
                return nnn.map(e => {
                    return arr[e];
                });
            }

            let as = this.arr.map(e => {
                return {
                    title: e.title,
                    url: e.little_image,
                    maxImage: e.bg_image,
                    mark: e.intro,
                    id: e.id,
                    pid: e.pid,
                };
            });
            return get(as, 10);
        },
    },
};
</script>

<style lang="less">
.iii {
    position: absolute;
    width: 100%;
    left: 0;
    height: 599px;
    overflow: hidden;
    z-index: 9;
    background-color: rgba(0, 0, 0, 0);
}
.iii:nth-child(2) {
    top: 600px;
}
.jin {
    animation: jin 0.5s;
}
@keyframes jin {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
.li {
    animation: li 0.5s;
}
@keyframes li {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
.jin1 {
    animation: jin1 0.5s;
}
@keyframes jin1 {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
.li1 {
    animation: li1 0.5s;
}
@keyframes li1 {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
.up {
    animation: up 0.5s;
}
@keyframes up {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
        transform: translate(0, -600px);
    }
}
.down {
    animation: down 0.5;
}
@keyframes down {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
// ------------
.product2 {
    width: 100%;
    // height: 745px;
    // margin-top: 50px;
    .p2Box {
        min-width: 1423px;
        height: 100%;
        margin: 0 auto;
        overflow: hidden;
        .p2bTop {
            width: 100%;
            text-align: center;
            overflow: hidden;
            margin-top: 30px;
            .pt1 {
                width: 100%;
                height: 40px;
                line-height: 40px;
                font-size: 28px;
                font-weight: bold;
            }
            .pt2 {
                // width: 50%;
                height: 24px;
                line-height: 24px;
                margin: 0 auto 0px;
                margin-top: 10px;
                font-size: 14px;
                // .p21 {
                //     float: left;
                // }
                .p22 {
                    // float: left;
                    margin-left: 10px;
                    color: #7781f1;
                    cursor: pointer;
                }
            }
        }
        .p2bList {
            width: 100%;
            height: 600px;
            margin-top: 20px;
            overflow: hidden;
            position: relative;
            z-index: 99;
            ul {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: space-around;
                flex-wrap: wrap;
                position: relative;
                z-index: 98;
                .p2bLi {
                    // width: 283px;
                    width: 20%;
                    height: 299px;
                    box-sizing: border-box;
                    border-right: 1px solid #d0d9e6;
                    border-bottom: 1px solid #d0d9e6;
                    position: relative;
                    cursor: pointer;
                    .p2Bg {
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        left: 0;
                        top: 0;
                        z-index: -1;
                    }
                    .plBox {
                        width: 205px;
                        height: 70%;
                        margin: 0 auto;
                        margin-top: 15%;
                        overflow: hidden;
                        .pb1 {
                            width: 100%;
                            height: 28px;
                            line-height: 28px;
                            font-size: 20px;
                            font-weight: bold;
                        }
                        .pb2 {
                            width: 100%;
                            height: 48px;
                            line-height: 24px;
                            font-size: 14px;
                            margin-top: 20px;
                        }
                        .pb3 {
                            width: 100px;
                            height: 20px;
                            overflow: hidden;
                            margin-top: 50px;

                            .p3t {
                                width: 56px;
                                height: 19px;
                                line-height: 19px;
                                float: left;
                                font-size: 14px;
                                transform: translateX(-56px);
                                transition: 0.5s;
                                color: white;
                                font-weight: bold;
                            }
                            .p3I {
                                width: 20px;
                                height: 20px;
                                overflow: hidden;
                                float: left;
                                margin-left: 10px;
                                transform: translateX(-56px);
                                transition: 0.5s;
                                .p3Icon {
                                    transform: translateX(-25px);
                                    transition: 0.5s;
                                    transition: 0.5s;
                                    width: 70px;
                                    height: 20px;
                                }
                            }
                        }
                    }
                }
                // .p3t:hover {
                //   font-size: 24px;
                // }
                .p2bLi:hover {
                    background-color: rgba(31, 49, 86, 0.8);
                    color: white;
                    .pb3 {
                        .p3t {
                            transform: translateX(0px);
                        }
                        .p3t:hover {
                            color: #7781f1;
                        }
                        .p3I {
                            transform: translateX(0px);
                            .p3Icon {
                                transform: translateX(-50px);
                            }
                        }
                    }
                }
            }
        }
    }
}
.p2bLi {
    background-color: rgba(0, 0, 0, 0);
    .imgStatus {
        width: 100%;
        height: 100%;
        position: relative;
        display: block;
        // opacity: 1;
        & > img {
            position: absolute;
            left: 0;
            top: 0;
        }
    }
    .plBox {
        position: absolute;
        top: 0px;
        left: 50%;
        transform: translateX(-50%);
    }
}
</style>
