<template>
    <div class="priceBox">
        <!-- <img src="https://iv.vu818.com/img/bj2.jpg" alt="" class="pcbBg" /> -->
        <!-- 标题 -->
        <div class="pbTitle">
            <p class="pbBig">贴心专业诊断</p>
            <p class="pbSmall">开发软件，不懂价格，怕吃亏，还怕被骗？威有官网免费报价 服务透明，实价无欺</p>
        </div>
        <!-- 类型选择 -->
        <div class="pbTypeTab">
            <ul>
                <li v-for="el in dataList" :key="el.id" :class="{ pbActive: isActive == el.id }" @click="isActive = el.id">
                    {{ el.title }}
                </li>
            </ul>
        </div>
        <!-- 功能选择 -->
        <div class="funcitonBox">
            <!-- 头部进度 -->
            <div class="fbTop">
                <div class="fbtBg">
                    <div></div>
                    <div></div>
                </div>
                <template v-for="el in ['选取所需网站功能', '填写您的联系方式', '完成，等待专家报价']">
                    <div :key="el" class="ftNameBox">
                        <!-- 圆 -->
                        <div class="cirCularBox">
                            <img alt="" src="http://iv.vu818.com/img/vu818.png" />
                        </div>
                        <div class="ftText">{{ el }}</div>
                    </div>
                </template>
                <div class="tag">{{ isActive == 1 ? "企业官网" : isActive == 2 ? "营销型网站" : "电商网站" }}</div>
            </div>
            <!-- ----------- -->
            <div v-show="jd == 1" class="fbContent">
                <div class="fcContent1">
                    <ul class="fcList1">
                        <li
                            v-for="(e, i) in activeSecond"
                            :key="e.id"
                            :style="{ width: `${100 / activeSecond.length}%` }"
                            class="fl1Li"
                        >
                            <!-- 图标 -->
                            <div class="fcIcon">
                                <img v-if="e.image" :src="e.image" class="fiImg" />
                            </div>
                            <!-- 名称 -->
                            <div class="fcName">
                                <p class="fcTop">{{ e.title }}</p>
                                <p class="fcBottom">{{ e.english }}</p>
                            </div>
                            <!-- 功能列表 -->
                            <div class="fcList">
                                <ul class="flList">
                                    <li v-for="item in e.children" :key="item.id">{{ item.title }}</li>
                                </ul>
                            </div>
                            <!-- 勾选框 -->
                            <div class="fcInput">
                                <input :id="'ck' + i" v-model="abc[i]" type="checkbox" />
                                <label :for="'ck' + i" class="inputText">选择该功能</label>
                            </div>
                        </li>
                    </ul>
                    <!-- 下一步 -->
                    <div class="bfBtn">
                        <el-button :class="{ isDisabled: !status }" :disabled="!status" class="forwardBtn" @click="jd = 2"
                            >下一步
                        </el-button>
                    </div>
                </div>
            </div>
            <div v-show="jd == 2" class="fcContent2">
                <div class="fcFormBox">
                    <ul class="ffbList">
                        <li>
                            <div class="flLists">
                                <div class="formName">
                                    <div class="fnIcon">
                                        <img alt="" class="fiImg" src="http://iv.vu818.com/img/vu818.png" />
                                    </div>
                                    <p class="fnName">
                                        <span style="color: red"> * </span>
                                        公司名称
                                    </p>
                                </div>
                                <div class="formInput">
                                    <input v-model="form.company" maxlength="50" type="text" />
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="flLists">
                                <div class="formName">
                                    <div class="fnIcon">
                                        <img alt="" class="fiImg2" src="http://iv.vu818.com/img/vu818.png" />
                                    </div>
                                    <p class="fnName">
                                        <span style="color: red"> * </span>
                                        邮箱地址
                                    </p>
                                </div>
                                <div class="formInput">
                                    <input v-model="form.email" maxlength="50" type="email" />
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="flLists">
                                <div class="formName">
                                    <div class="fnIcon">
                                        <img alt="" class="fiImg3" src="http://iv.vu818.com/img/vu818.png" />
                                    </div>
                                    <p class="fnName">
                                        <span style="color: red"> * </span>
                                        联系电话
                                    </p>
                                </div>
                                <div class="formInput">
                                    <input v-model="form.phone" maxlength="11" type="tel" @input="ipt" />
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="flLists">
                                <div class="formName">
                                    <div class="fnIcon">
                                        <img alt="" class="fiImg4" src="http://iv.vu818.com/img/vu818.png" />
                                    </div>
                                    <p class="fnName">
                                        <span style="color: red"> * </span>
                                        联系人
                                    </p>
                                </div>
                                <div class="formInput">
                                    <input v-model="form.name" maxlength="50" type="text" />
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="flLists">
                                <div class="formName">
                                    <div class="fnIcon">
                                        <img alt="" class="fiImg5" src="http://iv.vu818.com/img/vu818.png" />
                                    </div>
                                    <p class="fnName">
                                        <span style="color: red"> * </span>
                                        所在城市
                                    </p>
                                </div>
                                <div class="formInput">
                                    <input v-model="form.city" maxlength="50" type="text" />
                                </div>
                            </div>
                        </li>
                        <li class="theSix">
                            <div class="flLists">
                                <div class="formName">
                                    <div class="fnIcon">
                                        <img alt="" class="fiImg6" src="http://iv.vu818.com/img/vu818.png" />
                                    </div>
                                    <p class="fnName">
                                        <span style="color: red"> * </span>
                                        验证码
                                    </p>
                                </div>
                                <div class="formInput">
                                    <input v-model.trim="form.code" maxlength="50" placeholder="请注意区分大小写" type="text" />
                                </div>
                                <div class="yzm">
                                    <button id="yzmBtn" @click="can">获取验证码</button>
                                    <img v-show="form.image" id="yzmImg" :src="form.image" title="看不清？点击an换一张" />
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="backSubmit">
                    <div class="backBtn" @click="jd = 1">上一步</div>
                    <div class="submitBtn" @click="commitForm">提交</div>
                </div>
            </div>
            <div v-show="jd === 3" class="fcContent3">
                <div class="overText">
                    <p class="otText">您的需求已提交成功，请耐心等待</p>
                </div>
                <div class="overBox">
                    <div class="overBtn" @click="ok">完成</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import QueryString from "qs";

export default {
    name: "Price",
    data() {
        return {
            // 网站类型切换
            isActive: null,
            dataList: [],
            abc: new Array(20),
            form: {
                name: "",
                phone: "",
                email: "",
                company: "",
                city: "",
                channel: "贴心专业诊断",
                code: "",
                web_cat_id: [],
                image: "",
            },
            jd: 1,
            // code: null,
            // yzmImg: "",
        };
    },
    async created() {
        this.dataList = await this.getData();
        this.isActive = this.dataList[0].id;
    },
    mounted() {
        // this.can();
    },
    methods: {
        async getData() {
            let { data } = await axios.get("/api/mail/wcshow");
            data.forEach(e => {
                e.level = 1;
                if (e.children) {
                    e.children.forEach(el => {
                        el.level = 2;
                        if (el.children) {
                            el.children.forEach(ele => {
                                ele.level = 3;
                            });
                        }
                    });
                }
            });
            return data;
        },
        // 提交表单
        async commitForm() {
            let { form } = this;
            for (const k in form) {
                if (!form[k]) return this.$message.error("请勿为空！");
            }
            // if (form.code !== this.code) {
            //     this.$message.error("验证码错误请重新输入");
            //     this.form.code = "";
            //     this.can();
            //     return;
            // }
            form.web_cat_id = [];
            this.abc.forEach((e, i) => {
                if (e === true) {
                    form.web_cat_id.push(this.activeSecond[i].id);
                }
            });
            form.web_cat_id = form.web_cat_id.join(",");
            let { data } = await axios.post("/api/mail/add", QueryString.stringify(form));
            if (data.code !== 200) {
                this.$message.error(data.msg);
                this.can();
            } else {
                this.$message.success(`恭喜你是今天第${data.count}位求助者，请耐心等待`);
                this.jd = 3;
            }
        },
        // 完成
        ok() {
            this.jd = 1;
            this.abc = new Array(20);
            this.form = {
                name: "",
                phone: "",
                email: "",
                company: "",
                city: "",
                channel: "贴心专业诊断",
                code: "",
                web_cat_id: [],
                image: "",
            };
        },
        // 绘制验证码
        async can() {
            let { data } = await axios.get("api/project/startcode");
            this.form.image = data;
            // this.yzmImg = data;
        },
        ipt(e) {
            let val = Number(e.data);
            this.$nextTick(() => {
                if (isNaN(val)) {
                    let p = this.form.phone;
                    this.form.phone = p.replaceAll(e.data, "");
                }
            });
        },
    },
    computed: {
        status() {
            if (this.abc.filter(e => typeof e == "boolean").find(e => e)) return true;
            return false;
        },
        activeSecond() {
            if (!this.dataList.length) return [];
            return this.dataList.find(e => e.id == this.isActive).children;
        },
    },
    watch: {
        isActive() {
            this.abc = new Array(20);
            this.jd = 1;
        },
    },
};
</script>

<style lang="less" scoped>
.isDisabled {
    background: #ebeef5 !important;
    color: #c0c4cc !important;
}

.priceBox {
    width: 100%;
    height: 834px;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    // background: url("vu818.com/vu818com/img/images/bj2.jpg") no-repeat;
    .pcbBg {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        z-index: -1;
    }

    // 标题
    .pbTitle {
        width: 430px;
        // height: 110px;
        margin: 0 auto;
        // margin-top: 40px;
        .pbBig {
            width: 100%;
            height: 65px;
            line-height: 65px;
            text-align: center;
            font-size: 36px;
            font-weight: 900;
        }

        .pbSmall {
            width: 85%;
            height: 48px;
            margin: 10px auto;
            font-size: 14px;
            line-height: 22px;
            text-align: center;
        }
    }

    // 类型选择
    .pbTypeTab {
        width: 560px;
        height: 50px;
        margin: 0 auto;
        margin-top: 20px;
        border-radius: 20px;

        ul {
            width: 100%;
            height: 100%;
            display: flex;

            li {
                cursor: pointer;
                width: 40%;
                height: 100%;
                text-align: center;
                line-height: 50px;
                box-sizing: border-box;
                box-shadow: 1px 1px 10px 1px gainsboro;
            }

            li:nth-child(1) {
                border-top-left-radius: 20px;
                border-bottom-left-radius: 20px;
            }

            li:nth-child(3) {
                border-top-right-radius: 20px;
                border-bottom-right-radius: 20px;
            }

            .pbActive {
                background: #f9f9f9;
                // color: white;
            }
        }
    }

    // 功能选择
    .funcitonBox {
        max-width: 99%;
        height: 600px;
        margin: 0 auto;
        margin-top: 30px;
        border-radius: 20px;
        background-color: rgb(255, 250, 250);
        box-shadow: 1px 1px 10px 1px gray;
        // 头部进度
        .fbTop {
            width: 100%;
            height: 20%;
            display: flex;
            justify-content: space-between;
            position: relative;
            border-top-left-radius: 20px;
            border-top-right-radius: 20px;
            overflow: hidden;

            .fbtBg {
                width: 100%;
                height: 100%;
                position: absolute;

                & > div:nth-of-type(1) {
                    background-image: url("https://iv.vu818.com/img/vu818.png");
                    height: 100%;
                    background-repeat: no-repeat;
                    background-position: 0px -1245px;
                    width: 100px;
                    position: absolute;
                    top: 0;
                    left: 30%;
                }

                & > div:nth-of-type(2) {
                    background-image: url("https://iv.vu818.com/img/vu818.png");
                    height: 100%;
                    background-repeat: no-repeat;
                    background-position: 0px -1245px;
                    width: 100px;
                    position: absolute;
                    top: 0;
                    left: 62%;
                }
            }

            // 3个盒子
            .ftNameBox {
                width: 34%;
                height: 100%;
                text-align: center;
                position: relative;
                // 右上角标签文字

                //  圆
                .cirCularBox {
                    width: 53px;
                    height: 50%;
                    line-height: 60px;
                    margin: 0 auto;
                    margin-top: 15px;
                    box-sizing: border-box;
                    position: relative;
                    overflow: hidden;

                    img {
                        position: absolute;
                        top: -330px;
                        left: -1143px;
                    }
                }

                //  简介
                .ftText {
                    width: 100%;
                    height: 50%;
                    font-size: 18px;
                    color: #7781f1;
                }
            }

            .tag {
                position: absolute;
                right: -60px;
                top: 30px;
                transform: rotate(45deg);
                font-size: 14px;
                z-index: 99;
                color: white;
                width: 200px;
                height: 26px;
                line-height: 26px;
                background-image: linear-gradient(90deg, #1b7ef9, #d8e6f1);
            }
        }

        // 选择内容
        .fbContent {
            width: 100%;
            height: 70%;
            // 选取网站功能页
            .fcContent1 {
                width: 100%;
                height: 100%;

                form {
                    width: 100%;
                    height: 100%;
                }

                .fcList1 {
                    width: 100%;
                    height: 100%;
                    display: flex;

                    .fl1Li {
                        height: 100%;
                        background-color: white;
                        box-shadow: 1px 1px 10px 1px gainsboro;
                        transition: 0.5s;
                        // 图标
                        .fcIcon {
                            width: 80px;
                            height: 20%;
                            margin: 0 auto;
                            line-height: 120px;
                            text-align: center;
                            position: relative;
                            overflow: hidden;

                            .fiImg {
                                // position: absolute;
                                // top: -410px;
                                // left: -400px;
                                width: 100%;
                                height: 100%;
                                object-fit: contain;
                            }

                            .fiImg2 {
                                position: absolute;
                                top: -410px;
                                left: -327px;
                            }

                            .fiImg3 {
                                position: absolute;
                                top: -410px;
                                left: -250px;
                            }

                            .fiImg4 {
                                position: absolute;
                                top: -410px;
                                left: -179px;
                            }
                        }

                        // 名称
                        .fcName {
                            width: 100%;
                            height: 20%;
                            text-align: center;

                            .fcTop {
                                width: 100%;
                                height: 50%;
                                font-size: 25px;
                            }

                            .fcBottom {
                                width: 100%;
                                height: 30%;
                                font-size: 14px;
                            }
                        }

                        // 功能列表
                        .fcList {
                            width: 100%;
                            height: 40%;

                            .flList {
                                width: 100%;
                                height: 100%;
                                display: flex;
                                flex-wrap: wrap;
                                overflow: hidden;

                                li {
                                    width: 38%;
                                    height: 20%;
                                    padding-left: 23px;
                                    font-size: 15px;
                                    overflow: hidden;
                                    white-space: nowrap;
                                    text-overflow: ellipsis;
                                }
                            }
                        }

                        // 勾选框
                        .fcInput {
                            width: 50%;
                            height: 10%;
                            line-height: 50px;
                            margin: 0 auto;

                            input {
                                float: left;
                                width: 20px;
                                height: 100%;
                            }

                            .inputText {
                                width: 70%;
                                height: 100%;
                                float: left;
                                color: #7781f1;
                            }
                        }
                    }

                    .fl1Li:hover {
                        transform: scale(1.04);
                    }
                }

                // 下一步
                .bfBtn {
                    width: 30%;
                    height: 10%;
                    margin: 0 auto;

                    .forwardBtn {
                        width: 200px;
                        height: 40px;
                        margin: 0 auto;
                        margin-top: 10px;
                        box-sizing: border-box;
                        text-align: center;
                        // line-height: 40px;
                        font-size: 18px;
                        font-weight: 900;
                        border-radius: 10px;
                        background: linear-gradient(to right, #7781f1, #7781f1e0);
                        color: white;
                        // cursor: pointer;
                    }
                }
            }
        }

        // 联系方式页
        .fcContent2 {
            width: 100%;
            height: 70%;
            // 信息盒子
            .fcFormBox {
                width: 100%;
                height: 100%;

                .ffbList {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-wrap: wrap;

                    li,
                    .theSix {
                        width: 40%;
                        height: 20%;
                        margin: 0 auto;
                        margin-top: 25px;

                        .flLists {
                            width: 100%;
                            height: 50%;
                            margin: 0 auto;
                            margin-top: 25px;
                            border: 1px solid gainsboro;
                            border-radius: 10px;
                            overflow: hidden;
                            // 名称与图标
                            .formName {
                                width: 25%;
                                height: 100%;
                                float: left;
                                border-right: none;
                                box-sizing: border-box;

                                .fnIcon {
                                    width: 40px;
                                    height: 42px;
                                    position: relative;
                                    overflow: hidden;
                                    float: left;

                                    .fiImg {
                                        width: 900px;
                                        height: 1700px;
                                        position: absolute;
                                        top: -403px;
                                        right: -39px;
                                    }

                                    .fiImg2 {
                                        width: 900px;
                                        height: 1750px;
                                        position: absolute;
                                        top: -568px;
                                        right: -136px;
                                    }

                                    .fiImg3 {
                                        width: 910px;
                                        height: 1650px;
                                        position: absolute;
                                        top: -439px;
                                        right: -330px;
                                    }

                                    .fiImg4 {
                                        width: 1100px;
                                        height: 1700px;
                                        position: absolute;
                                        top: -552px;
                                        right: -125px;
                                    }

                                    .fiImg5 {
                                        width: 1000px;
                                        height: 1700px;
                                        position: absolute;
                                        top: -454px;
                                        left: -188px;
                                    }

                                    .fiImg6 {
                                        width: 1000px;
                                        height: 1700px;
                                        position: absolute;
                                        top: -451px;
                                        right: -46px;
                                    }
                                }

                                // 图标
                                .iconfont {
                                    display: block;
                                    font-size: 30px;
                                    width: 30%;
                                    height: 100%;
                                    line-height: 40px;
                                    text-align: center;
                                    float: left;
                                }

                                // 名称
                                .fnName {
                                    width: 65%;
                                    height: 100%;
                                    line-height: 40px;
                                    text-align: center;
                                    float: left;
                                }
                            }

                            // 输入框
                            .formInput {
                                width: 75%;
                                height: 100%;
                                float: left;
                                border-top-right-radius: 10px;
                                border-bottom-right-radius: 10px;
                                overflow: hidden;

                                input {
                                    border: none;
                                    width: 100%;
                                    height: 100%;
                                    outline: none;
                                    padding-left: 5px;
                                    box-sizing: border-box;
                                }
                            }
                        }
                    }

                    .theSix {
                        // 输入框
                        .flLists .formInput {
                            width: 38%;
                            height: 100%;
                            float: left;

                            input {
                                border-left: none;
                                border-color: #eee;
                                width: 100%;
                                height: 100%;
                                outline: none;
                            }
                        }

                        .yzm {
                            display: flex;
                            align-items: center;
                            white-space: nowrap;
                            height: 100%;
                            flex-direction: row-reverse;
                            #yzmBtn {
                                border: 1px solid #333;
                                background-color: white;
                                border-radius: 5px;
                                height: 80%;
                                padding: 0 5px;
                                cursor: pointer;
                                &:hover {
                                    color: #7781f1;
                                    border-color: #7781f1;
                                }
                            }

                            #yzmImg {
                                width: 120px;
                                // height: 43px;
                                height: 100%;
                                overflow: hidden;
                                float: left;
                                cursor: pointer;

                                & > canvas {
                                    width: 300px;
                                    height: 150px;
                                }
                            }
                        }
                    }
                }
            }

            // 上一步与提交按钮
            .backSubmit {
                width: 50%;
                height: 10%;
                margin: 0 auto;
                display: flex;
                justify-content: space-evenly;

                .backBtn {
                    width: 200px;
                    height: 40px;
                    float: left;
                    margin: 0 auto;
                    margin-top: 10px;
                    box-sizing: border-box;
                    text-align: center;
                    line-height: 40px;
                    font-size: 18px;
                    font-weight: 900;
                    border-radius: 10px;
                    background: linear-gradient(to right, #7781f1, #7781f1e0);
                    color: white;
                    cursor: pointer;
                }

                .submitBtn {
                    width: 200px;
                    height: 40px;
                    float: left;
                    margin: 0 auto;
                    margin-top: 10px;
                    box-sizing: border-box;
                    text-align: center;
                    line-height: 40px;
                    font-size: 18px;
                    font-weight: 900;
                    border-radius: 10px;
                    background: linear-gradient(to right, #7781f1, #7781f1e0);
                    color: white;
                    cursor: pointer;
                }
            }
        }

        //  专家报价页
        .fcContent3 {
            width: 100%;
            height: 70%;
            text-align: center;

            .overText {
                width: 100%;
                height: 100%;

                .otText {
                    padding-top: 150px;
                    font-size: 50px;
                }
            }

            .overBox {
                width: 30%;
                height: 10%;
                margin: 0 auto;

                .overBtn {
                    width: 200px;
                    height: 40px;
                    margin: 0 auto;
                    margin-top: 10px;
                    box-sizing: border-box;
                    text-align: center;
                    line-height: 40px;
                    font-size: 18px;
                    font-weight: 900;
                    border-radius: 10px;
                    background: linear-gradient(to right, #7781f1, #7781f1e0);
                    color: white;
                    cursor: pointer;
                }
            }
        }
    }
}
</style>
